import { ApplicationDocument } from '../model/application.interface';
import { IApplicationEmailResponse, IReplyEmail } from '../model/email-response.interface';
import { IEmail } from '../model/email.interface';
import { ApplicationEmailResponse } from './email-response.class';
import { Company } from './organization.class';
import { TranslationKeys } from '../model/translation-object.interface';
import { User } from '../model/user.interface';
import { FileResponse, IFile } from '../model/upload-files.model';
import { linkify } from '../resources/shared-functions';
import { IAgreement } from '../model/agreement.model';

export class Email {

  applicationEmailResponses: ApplicationEmailResponse[] = [];
  applicationDocuments: ApplicationDocument[];
  createdAt: string;
  subject: string;
  content: string;
  senderName: string;
  senderEmail: string;
  sortDate: string;
  agreements: IAgreement[];

  constructor(email: IEmail, company: Company, currentUser?: User) {
    const {
      applicationEmailResponses,
      createdAt,
      id,
      subject,
      content, senderCustomer, senderEnterpriseManager, applicationDocuments, applicationAgreements,
    } = email;

    const sender = senderCustomer || senderEnterpriseManager;

    this.senderEmail = sender.email;

    const anonymousSender = sender.anonymousCustomer || sender.anonymousEnterpriseManager;
    this.senderName = anonymousSender ? company.name : sender.name;
    this.applicationDocuments = applicationDocuments;
    this.createdAt = createdAt;
    this.subject = subject;
    this.content = content;
    this.agreements = applicationAgreements;

    if (content && !content.includes('</a>')) {
      this.content = linkify(content);
    }

    if (applicationEmailResponses) {
      this.applicationEmailResponses = applicationEmailResponses
        .map((emailResponse: IApplicationEmailResponse) => new ApplicationEmailResponse(emailResponse, company, currentUser));
    }
  }

  public static setAttachments(uploadedFiles: FileResponse[], templateFiles: IFile[]): Record<string, string> {
    const attachments = {};

    templateFiles
      .forEach(({ name, file }: IFile) => {
        attachments[name] = file;
      });

    uploadedFiles
      .forEach(({ fileName, link }: FileResponse) => {
        attachments[fileName] = link;
      });

    return attachments;
  }

  public static setTemplateFiles(attachments: Record<string, string>): IFile[] {
    const templateFiles = [];

    if (attachments) {
      for (const [key, value] of Object.entries(attachments)) {
        templateFiles.push({ name: key, file: value });
      }
    }

    return templateFiles;
  }
}

export class ReplyEmail {

  jobTitle: string;
  companyLogo: string;
  senderName: string;
  subject: string;
  createdAt: string;
  id: number;
  content: string;
  applicationEmailResponses: IApplicationEmailResponse[];
  language: TranslationKeys;
  applicationDocuments: IFile[];
  agreements: IAgreement[];

  constructor({
    application,
    senderCustomer,
    senderEnterpriseManager,
    subject,
    createdAt,
    id,
    content,
    applicationEmailResponses,
    applicationDocuments,
    applicationAgreements
  }: IReplyEmail
  ) {
    const job = application.job || application.universalJob;

    this.jobTitle = job.jobTitle;
    this.companyLogo = job.company.companyLogo;
    this.subject = subject;
    this.createdAt = createdAt;
    this.id = id;
    this.agreements = applicationAgreements;
    this.content = content;

    if (!content.includes('</a>')) {
      this.content = linkify(content);
    }

    this.applicationEmailResponses = applicationEmailResponses;
    this.language = job.language;
    this.applicationDocuments = applicationDocuments;

    const sender = senderCustomer || senderEnterpriseManager;
    const anonymousSender = sender?.anonymousCustomer || sender?.anonymousEnterpriseManager;
    this.senderName = anonymousSender ? job.company.name : sender?.name;
  }
}

export class ResponseEmails {
  hasResponses = false;
  hasUnreadResponses = false;
  numberOfOwnUnreadResponses = 0;
  numberOfUnreadResponsesFromOthers = 0;
}
