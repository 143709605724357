import {
  ICandidateApplication,
  Candidate,
  IPinnedApplication,
  PersonalityTest,
  IApplication,
  TestQuestion,
  IApplicationVideoQuestions,
  IBaseApplication,
  AskForCvRequest,
  AskForSsnAndFullNameRequest
} from '../model/application.interface';
import { ApplicationJob } from '../model/job.interface';
import { IRefApp } from '../model/ref-app.interface';
import { ApplicationEmailResponse } from './email-response.class';
import { Email, ResponseEmails } from './email.class';
import { Company } from './organization.class';
import { IEmail } from '../model/email.interface';
import { EmailNotification } from './notification.class';
import { User } from '../model/user.interface';
import { QUIZ_MODULES } from '../resources/quiz-modules';
import { IComment } from '../model/comment.interface';
import { IHiringStatus } from '../model/hiring-status.interface';
import { ISms } from '../model/sms.interface';
import { UniversalOption } from '../model/universal-option.interface';
import { changeVideoExtension } from '../resources/video-url-transformation-functions';
import { Comment } from './comments.class';
import { SmsMessage } from './sms-messages.class';
import { ISriBgCheckDetails } from '../model/sri.interface';
import { IFile } from '../model/upload-files.model';
import { convertStringDateToJSDate } from '../resources/shared-functions';
import { AlvaAssessment } from '../model/alva-labs.interface';
import { IAgreement } from '../model/agreement.model';

const emails1 = [
  {
    id: 179955,
    senderCustomer: null,
    senderEnterpriseManager: {
      createdAt: '2022-04-12T09:36:53+02:00',
      id: 2,
      email: 'sanja@enterprise.com',
      name: 'Sanja Cukaric',
      anonymousEnterpriseManager: false,
      guid: '81b14395-e4ab-4869-a1ec-97a6463212d8'
    },
    subject: 'r444',
    content: '<p>MAIL 1-CUSTOMER</p>',
    greeting: null,
    applicationEmailResponses: [
      {
        id: 3686,
        content: '<p style="margin: 0; padding: 0;  font-size: 13px; line-height: 1.4;">RESPONSE na mail 1-kandidat</p>',
        applicationDocuments: [
          {
            id: 25925,
            name: 'Josefine Test CV.docx',
            file: 'https://docs.uhigher.com/application_email_response/54bfdeb86247492d9716bf60a697d8f9/Josefine_Test_CV.docx',
            uploadedBy: 'candidate_via_application_email_response',
            createdAt: '2025-01-14T12:06:06+01:00'
          }
        ],
        readByCustomers: [],
        readByEnterpriseManagers: [],
        confirmationEmailSent: true,
        readByEmailSender: false,
        createdAt: '2025-01-14T12:06:06+01:00',
        applicationEmailResponseReply: {
          id: 179955,
          senderCustomer: null,
          senderEnterpriseManager: {
            createdAt: '2022-04-12T09:36:53+02:00',
            id: 2,
            email: 'sanja@enterprise.com',
            name: 'Sanja Cukaric',
            anonymousEnterpriseManager: false,
            guid: '81b14395-e4ab-4869-a1ec-97a6463212d8'
          },
          subject: 'r444',
          content: '<p style="margin: 0; padding: 0; font-size: 13px; line-height: 1.4;">MAIL 2-CUSTOMER</p>',
          greeting: null,
          applicationEmailResponses: [
            {
              id: 3686,
              content: '<p style="margin: 0; padding: 0;  font-size: 13px; line-height: 1.4;">response na mail 2-kandidat</p>',
              applicationDocuments: [
                {
                  id: 25925,
                  name: 'Josefine Test CV.docx',
                  file: 'https://docs.uhigher.com/application_email_response/54bfdeb86247492d9716bf60a697d8f9/Josefine_Test_CV.docx',
                  uploadedBy: 'candidate_via_application_email_response',
                  createdAt: '2025-01-14T12:06:06+01:00'
                }
              ],
              readByCustomers: [],
              readByEnterpriseManagers: [],
              confirmationEmailSent: true,
              readByEmailSender: false,
              createdAt: '2025-01-14T12:06:06+01:00',
              applicationEmailResponseReply: {
                id: 179955,
                senderCustomer: null,
                senderEnterpriseManager: {
                  createdAt: '2022-04-12T09:36:53+02:00',
                  id: 2,
                  email: 'sanja@enterprise.com',
                  name: 'Sanja Cukaric',
                  anonymousEnterpriseManager: false,
                  guid: '81b14395-e4ab-4869-a1ec-97a6463212d8'
                },
                subject: 'r444',
                content: '<p style="margin: 0; padding: 0; font-size: 13px; line-height: 1.4;">MAIL 2-CUSTOMER</p>',
                greeting: null,
                applicationEmailResponses: [
                  {
                    id: 3686,
                    content: '<p style="margin: 0; padding: 0;  font-size: 13px; line-height: 1.4;">response na mail 2-kandidat</p>',
                    applicationDocuments: [
                      {
                        id: 25925,
                        name: 'Josefine Test CV.docx',
                        file: 'https://docs.uhigher.com/application_email_response/54bfdeb86247492d9716bf60a697d8f9/Josefine_Test_CV.docx',
                        uploadedBy: 'candidate_via_application_email_response',
                        createdAt: '2025-01-14T12:06:06+01:00'
                      }
                    ],
                    readByCustomers: [],
                    readByEnterpriseManagers: [],
                    confirmationEmailSent: true,
                    readByEmailSender: true,
                    createdAt: '2025-01-14T12:06:06+01:00',
                    applicationEmailResponseReply: null
                  }
                ],
                applicationDocuments: [],
                applicationAgreements: [],
                changedReceiverEmail: null,
                createdAt: '2025-01-14T12:05:34+01:00'
              },
            }
          ],
          applicationDocuments: [],
          applicationAgreements: [],
          changedReceiverEmail: null,
          createdAt: '2025-01-14T12:05:34+01:00'
        },
      },
      {
        id: 3686,
        content: '<p style="margin: 0; padding: 0;  font-size: 13px; line-height: 1.4;">RESPONSE na mail 1-kandidat</p>',
        applicationDocuments: [
          {
            id: 25925,
            name: 'Josefine Test CV.docx',
            file: 'https://docs.uhigher.com/application_email_response/54bfdeb86247492d9716bf60a697d8f9/Josefine_Test_CV.docx',
            uploadedBy: 'candidate_via_application_email_response',
            createdAt: '2025-01-14T12:06:06+01:00'
          }
        ],
        readByCustomers: [],
        readByEnterpriseManagers: [],
        confirmationEmailSent: true,
        readByEmailSender: false,
        createdAt: '2025-01-14T12:06:06+01:00',
        applicationEmailResponseReply: {
          id: 179955,
          senderCustomer: null,
          senderEnterpriseManager: {
            createdAt: '2022-04-12T09:36:53+02:00',
            id: 2,
            email: 'sanja@enterprise.com',
            name: 'Sanja Cukaric',
            anonymousEnterpriseManager: false,
            guid: '81b14395-e4ab-4869-a1ec-97a6463212d8'
          },
          subject: 'r444',
          content: '<p style="margin: 0; padding: 0; font-size: 13px; line-height: 1.4;">MAIL 2-CUSTOMER</p>',
          greeting: null,
          applicationEmailResponses: [
            {
              id: 3686,
              content: '<p style="margin: 0; padding: 0;  font-size: 13px; line-height: 1.4;">response na mail 2-kandidat</p>',
              applicationDocuments: [
                {
                  id: 25925,
                  name: 'Josefine Test CV.docx',
                  file: 'https://docs.uhigher.com/application_email_response/54bfdeb86247492d9716bf60a697d8f9/Josefine_Test_CV.docx',
                  uploadedBy: 'candidate_via_application_email_response',
                  createdAt: '2025-01-14T12:06:06+01:00'
                }
              ],
              readByCustomers: [],
              readByEnterpriseManagers: [],
              confirmationEmailSent: true,
              readByEmailSender: false,
              createdAt: '2025-01-14T12:06:06+01:00',
              applicationEmailResponseReply: {
                id: 179955,
                senderCustomer: null,
                senderEnterpriseManager: {
                  createdAt: '2022-04-12T09:36:53+02:00',
                  id: 2,
                  email: 'sanja@enterprise.com',
                  name: 'Sanja Cukaric',
                  anonymousEnterpriseManager: false,
                  guid: '81b14395-e4ab-4869-a1ec-97a6463212d8'
                },
                subject: 'r444',
                content: '<p style="margin: 0; padding: 0; font-size: 13px; line-height: 1.4;">MAIL 2-CUSTOMER</p>',
                greeting: null,
                applicationEmailResponses: [
                  {
                    id: 3686,
                    content: '<p style="margin: 0; padding: 0;  font-size: 13px; line-height: 1.4;">response na mail 2-kandidat</p>',
                    applicationDocuments: [
                      {
                        id: 25925,
                        name: 'Josefine Test CV.docx',
                        file: 'https://docs.uhigher.com/application_email_response/54bfdeb86247492d9716bf60a697d8f9/Josefine_Test_CV.docx',
                        uploadedBy: 'candidate_via_application_email_response',
                        createdAt: '2025-01-14T12:06:06+01:00'
                      }
                    ],
                    readByCustomers: [],
                    readByEnterpriseManagers: [],
                    confirmationEmailSent: true,
                    readByEmailSender: true,
                    createdAt: '2025-01-14T12:06:06+01:00',
                    applicationEmailResponseReply: null
                  }
                ],
                applicationDocuments: [],
                applicationAgreements: [],
                changedReceiverEmail: null,
                createdAt: '2025-01-14T12:05:34+01:00'
              },
            }
          ],
          applicationDocuments: [],
          applicationAgreements: [],
          changedReceiverEmail: null,
          createdAt: '2025-01-14T12:05:34+01:00'
        },
      },
    ],
    applicationDocuments: [],
    applicationAgreements: [],
    changedReceiverEmail: null,
    createdAt: '2025-01-14T12:05:34+01:00'
  }
];

export class BaseApplication {
  static emails: Email[];
  static responseEmails: ResponseEmails;

  businessQuestionsScore: number;
  businessQuestionsStatusIconColor: string;
  candidate: Candidate;
  candidateName: string;
  communicated: boolean;
  createdAt: Date;
  candidateEditedViaReminderLinkAt: Date;
  emails: Email[];
  exportedToRefapp: boolean;
  exportedToSri: boolean;
  exportedToTilTid: boolean;
  exportedToAlva: boolean;
  id: number;
  inhouseQuestionAnsweredCorrectly: boolean;
  job?: ApplicationJob;
  knockoutQuestionsScore: number;
  orderOfQuestionsModules: QUIZ_MODULES[];
  personalityTestStatusIconColor: string;
  personalityTest: PersonalityTest;
  refappDetails: IRefApp;
  responseEmails: ResponseEmails;
  sriBackgroundCheckDetails: ISriBgCheckDetails;
  videoQuestionsStatusIconColor: string;
  askForCvRequest?: AskForCvRequest;
  linkedinProfile: string;
  isHidden?: boolean;
  alvaAssessment: AlvaAssessment;
  alvaCandidateId: number;
  alvaJobId: number;

  constructor(app: IBaseApplication, company: Company, currentUser: User) {
    this.askForCvRequest = app.askForCvRequest;
    this.businessQuestionsScore = app.businessQuestionsScore;
    this.businessQuestionsStatusIconColor = app.businessQuestionsStatusIconColor;
    this.candidate = app.candidate;
    this.candidateName = app.candidateAppliedWithName;
    this.communicated = app.communicated;
    const { emails, responseEmails } = BaseApplication.setEmails(emails1, company, currentUser);
    this.emails = emails;
    this.responseEmails = responseEmails;
    this.exportedToRefapp = !!app.refappReferenceCheck;
    this.exportedToSri = !!app.sriBackgroundCheck;
    this.exportedToTilTid = !!app.candidate.exportedTilTidEmployee;
    this.exportedToAlva = !!app.alvaAssessment;
    this.id = app.id;
    this.inhouseQuestionAnsweredCorrectly = app.inhouseQuestionAnsweredCorrectly;
    this.job = app.job || app.universalJob;
    this.knockoutQuestionsScore = app.knockoutQuestionsScore;
    this.orderOfQuestionsModules = app.orderOfQuestionsModules;
    this.personalityTestStatusIconColor = app.personalityTestStatusIconColor;
    this.personalityTest = app.psykometrikaResults;
    this.refappDetails = app.refappReferenceCheck;
    this.sriBackgroundCheckDetails = app.sriBackgroundCheck;
    this.videoQuestionsStatusIconColor = app.videoQuestionsStatusIconColor;
    this.linkedinProfile = app.linkedinProfile;
    this.isHidden = app.isHidden;
    this.alvaAssessment = app.alvaAssessment;
    this.alvaCandidateId = app.alvaCandidateId;
    this.alvaJobId = app.alvaJobId;

    const responses = new ResponseEmails();
    const newResponses = BaseApplication.setResponsesFromAllEmails(this.emails, currentUser, responses);
    this.responseEmails = newResponses;
  }

  public static setResponsesFromAllEmails(emails: Email[], currentUser: User, responseEmails: ResponseEmails): any {

    emails.forEach((email: Email) => {
      if (email.applicationEmailResponses.length) {
        email.applicationEmailResponses
          .forEach((response: ApplicationEmailResponse) => {
            if (response.unreadResponse) {
              responseEmails.hasUnreadResponses = true;

              email.senderEmail === currentUser.email
                ? responseEmails.numberOfOwnUnreadResponses++
                : responseEmails.numberOfUnreadResponsesFromOthers++;
            }

            if (response.emails.length) {
              BaseApplication.setResponsesFromAllEmails(response.emails, currentUser, responseEmails);
            }

            responseEmails.hasResponses = true;

          });
      }
    });

    return responseEmails;
  }

  public static convertEmails(emails: any[], company: Company, currentUser: User): { emails: Email[], responseEmails: ResponseEmails } {
    const responseEmails = new ResponseEmails();

    const newEmails = emails
      .map((iEmail: IEmail) => {
        const email = new Email(iEmail, company, currentUser);

        email.applicationEmailResponses
          .forEach((response: ApplicationEmailResponse) => {
            if (response.unreadResponse) {
              responseEmails.hasUnreadResponses = true;

              email.senderEmail === currentUser.email
                ? responseEmails.numberOfOwnUnreadResponses++
                : responseEmails.numberOfUnreadResponsesFromOthers++;
            }
          });

        return email;
      });

    const emailWithResponse = newEmails.find((email: Email) => email.applicationEmailResponses.length > 0);
    responseEmails.hasResponses = !!emailWithResponse;

    return { emails: newEmails, responseEmails };
  }

  public static setEmails(applicationEmails: any[], company: Company, currentUser: User): any {
    const { emails, responseEmails } = BaseApplication.convertEmails(applicationEmails || [], company, currentUser);

    return { emails, responseEmails };
  }
}

export class Application extends BaseApplication {

  candidatePersonalDataRemoved: boolean;
  page: number;
  pinned: boolean;
  position: number;

  constructor(app: IApplication, company: Company, currentUser: User, pinned: boolean = false) {
    super(app, company, currentUser);

    // on apps per hiring status we dont get proper date object
    this.createdAt = convertStringDateToJSDate(app.createdAt as string);
    this.candidateEditedViaReminderLinkAt = app.candidateEditedViaReminderLinkAt ?
      convertStringDateToJSDate(app.candidateEditedViaReminderLinkAt as string) :
      null;
    this.candidatePersonalDataRemoved = app.candidatePersonalDataRemoved;
    this.pinned = pinned;

    if (pinned) {
      this.position = app.pinnedApplications
        .find((pinnedApp: IPinnedApplication) => {
          const pinnedByUser = pinnedApp.pinnedByCustomer || pinnedApp.pinnedByEnterpriseManager;
          return pinnedByUser.email === currentUser.email;
        })?.position;
    }
  }

  public setResponseEmailsFromNotifications(receivedEmails: EmailNotification[], user: User): void {
    this.responseEmails.hasUnreadResponses = false;
    this.responseEmails.numberOfOwnUnreadResponses = 0;
    this.responseEmails.numberOfUnreadResponsesFromOthers = 0;

    const receivedEmailsForCurrentApp = receivedEmails
      .filter(({ applicationId }: EmailNotification) => applicationId === this.id);

    if (!receivedEmailsForCurrentApp.length) {
      return;
    }

    this.responseEmails.hasResponses = true;
    const unreadResponsesForCurrentApp = receivedEmailsForCurrentApp
      .filter(({ readBy }: EmailNotification) => !readBy.includes(user.email));

    if (!unreadResponsesForCurrentApp.length) {
      return;
    }

    this.responseEmails.hasUnreadResponses = true;
    const unreadResponsesFromCurrentUser = unreadResponsesForCurrentApp
      .filter(({ sender }: EmailNotification) => sender.email === user.email);
    this.responseEmails.numberOfOwnUnreadResponses = unreadResponsesFromCurrentUser.length;
    this.responseEmails.numberOfUnreadResponsesFromOthers = unreadResponsesForCurrentApp.length - unreadResponsesFromCurrentUser.length;
  }
}

export class CandidateApplication extends BaseApplication {

  applicationComplete: boolean;
  applicationUrl?: string;
  askForSsnAndFullNameRequest: AskForSsnAndFullNameRequest;
  businessQuestions: TestQuestion;
  businessQuestionsMetaData: TestQuestion;
  comments: Comment[];
  documents: IFile[];
  guid: string;
  hiringStatus: IHiringStatus;
  inhouseQuestion: TestQuestion;
  knockoutQuestions: TestQuestion;
  phoneNumber: string;
  smsMessages: SmsMessage[];
  tags: UniversalOption[];
  isAnonymous: boolean;
  videoQuestions: ApplicationVideoQuestion[] = [];
  agreements: IAgreement[];

  constructor(app: ICandidateApplication, currentUser: User, company: Company) {
    super(app, company, currentUser);

    this.applicationComplete = app.applicationComplete;
    this.applicationUrl = app.applicationUrl;
    this.askForSsnAndFullNameRequest = app.askForSsnAndFullNameRequest;
    this.businessQuestions = app.businessQuestions;
    this.businessQuestionsMetaData = app.businessQuestionsMetaData;
    this.comments = app.comments.map((comment: IComment) => new Comment(comment, currentUser));
    this.createdAt = app.createdAt as Date;
    this.candidateEditedViaReminderLinkAt = app.candidateEditedViaReminderLinkAt ? app.candidateEditedViaReminderLinkAt as Date : null;
    this.documents = Array.isArray(app.documents) ? app.documents : Object.values(app.documents);
    this.mergeAllEmails(currentUser, company);
    this.emails = this.sortEmails(this.emails);
    this.guid = app.guid;
    this.hiringStatus = app.hiringStatus;
    this.inhouseQuestion = app.inhouseQuestion;
    this.knockoutQuestions = app.knockoutQuestions;
    this.phoneNumber = app.phoneNumber;
    this.smsMessages = app.smsMessages ? app.smsMessages.map((message: ISms) => new SmsMessage(message, company)) : [];
    this.tags = app.tags;
    this.setVideoQuestions(app.videoQuestions);
    if (!app.candidateAppliedWithName && !app.phoneNumber && !app.candidate.email) {
      this.isAnonymous = true;
    }
    this.agreements = app.applicationAgreements;
  }

  private mergeAllEmails(currentUser: User, company: Company): void {
    let askForCvEmails = [];

    if (this.askForCvRequest) {
      const { applicationAskForCvRequestEmails } = this.askForCvRequest;
      askForCvEmails = this.setOtherTypesOfEmails(company, currentUser, applicationAskForCvRequestEmails);
    }

    let askForSsnAndFullNameEmails = [];

    if (this.askForSsnAndFullNameRequest) {
      const { applicationAskForSsnAndFullNameRequestEmails } = this.askForSsnAndFullNameRequest;
      askForSsnAndFullNameEmails = this.setOtherTypesOfEmails(company, currentUser, applicationAskForSsnAndFullNameRequestEmails);
    }

    this.emails = [...this.emails, ...askForCvEmails, ...askForSsnAndFullNameEmails];
  }

  private setOtherTypesOfEmails(company: Company, currentUser: User, emails: IEmail[]): Email[] {
    if (!emails) {
      return [];
    }

    return emails.map((iEmail: IEmail) => new Email(iEmail, company, currentUser));
  }

  private setVideoQuestions(videoQuestions: IApplicationVideoQuestions): void {
    if (!videoQuestions) {
      return;
    }

    this.videoQuestions.push(new ApplicationVideoQuestion(videoQuestions.q1, videoQuestions.q1answer));
    this.videoQuestions.push(new ApplicationVideoQuestion(videoQuestions.q2, videoQuestions.q2answer));
    this.videoQuestions.push(new ApplicationVideoQuestion(videoQuestions.q3, videoQuestions.q3answer));
  }

  //sortiranje po datumu kreiranja
  private sortEmails(emails: Email[]): Email[] {
    emails.forEach((sentEmail: Email) => {
      let emailDate = sentEmail.createdAt;

      if (sentEmail.applicationEmailResponses) {
        sentEmail.applicationEmailResponses
          .forEach(({ createdAt }: ApplicationEmailResponse) => {
            if (createdAt.localeCompare(emailDate) === 1) {
              emailDate = createdAt;
            }
          });
      }

      sentEmail.sortDate = emailDate;
    });

    emails = emails
      .sort((a: Email, b: Email) => {
        return new Date(b.sortDate).valueOf() - new Date(a.sortDate).valueOf();
      });

    return emails;
  }
}

export class ApplicationVideoQuestion {

  question: string;
  answer: string;
  poster: string;

  constructor(question: string, answer: string) {
    this.question = question;
    this.answer = answer;

    if (answer) {
      this.poster = changeVideoExtension(answer, 'jpg');
    }
  }
}
