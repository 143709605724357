import { ApplicationDocument } from '../model/application.interface';
import { User, UserCommunicator } from '../model/user.interface';
import { IApplicationEmailResponse } from '../model/email-response.interface';
import { ENTERPRISE_ROLES } from '../resources/roles';
import { BaseApplication } from './application.class';
import { Company } from './organization.class';
import { Email, ResponseEmails } from './email.class';

export class ApplicationEmailResponse {

  applicationDocuments: ApplicationDocument[];
  content: string;
  createdAt: string;
  id: number;
  unreadResponse?: boolean;
  emails: Email[];
  responseEmails: ResponseEmails;

  constructor(
    { readByCustomers, readByEnterpriseManagers, createdAt, id, applicationDocuments, content, readByEmailSender, applicationEmailResponseReply }: IApplicationEmailResponse,
    company: Company,
    currentUser: User
  ) {
    this.applicationDocuments = applicationDocuments;
    this.content = content;
    this.createdAt = createdAt;
    this.id = id;
    const { emails, responseEmails } = applicationEmailResponseReply ?
      BaseApplication.setEmails([applicationEmailResponseReply], company, currentUser) : { emails: [], responseEmails: {} };
    this.emails = emails;
    this.responseEmails = responseEmails;

    if (readByEmailSender) {
      return;
    }

    if (ENTERPRISE_ROLES.includes(currentUser.role)) {
      this.unreadResponse = !readByEnterpriseManagers.find(({ email }: UserCommunicator) => email === currentUser.email);
    } else {
      this.unreadResponse = !readByCustomers.find(({ email }: UserCommunicator) => email === currentUser.email);
    }
  }
}
