import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { ScrollToService } from 'src/app/services/scroll-to.service';
import { RecruitmentService } from 'src/app/services/recruitment.service';
import { LoaderService } from 'src/app/services/loader.service';
import { PerformActionService } from 'src/app/services/perform-action.service';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandlingService } from 'src/app/services/handle-error.service';
import { catchError, finalize, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { AbstractModal } from '../../modal/abstract-modal';
import { ModalService } from 'src/app/services/modal.service';
import { AgreementModal, CandidateCardModal, Modal } from 'src/app/classes/modal.class';
import { CandidateCardModalData, UniversalModalData, UpdatePhoneModalData } from 'src/app/model/modal.interface';
import { SetupService } from 'src/app/services/setup.service';
import { ExportPlatform, ExportPlatforms } from 'src/app/model/perform-action.interface';
import { NotificationService } from 'src/app/services/notification.service';
import { ExportService } from 'src/app/services/export.service';
import { CandidateApplication } from 'src/app/classes/application.class';
import { UpdatePhoneModalComponent } from '../../modal/update-phone-modal/update-phone-modal.component';
import { Roles } from 'src/app/model/role.interface';
import { UniversalModalComponent } from '../../modal/universal-modal/universal-modal.component';
import { AlvalabsCandidate, AlvaNewRoleFit, AlvaScoreMatch } from 'src/app/model/alva-labs.interface';
import { ActivatedRoute, Params } from '@angular/router';
import { AgreementFormClass } from 'src/app/classes/agreement-form.class';
import { AgreementService } from 'src/app/services/agreement.service';

@Component({
  selector: 'app-candidate-card',
  templateUrl: './candidate-card.component.html',
  styleUrls: ['./candidate-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CandidateCardComponent extends AbstractModal implements OnInit, OnDestroy {

  modal: CandidateCardModal;

  application: CandidateApplication = null;
  jobIds: string[];
  universalJobId: string;
  areAgreementsEnabled: boolean;

  modules = {
    knockoutQuestions: false,
    businessQuestions: false,
    personalityTest: false,
    videoQuestions: false,
  };

  scrollToComponents = {
    email: {
      id: '#email',
      componentName: 'CandidateEmailComponent'
    },
    sms: {
      id: '#sms',
      componentName: 'CandidateSmsComponent'
    },
    test: {
      id: '#test',
      componentName: 'CandidateTestComponent'
    },
    tilTid: {
      id: '#tilTid',
      componentName: 'TilTidComponent'
    },
    comment: {
      id: '#comment',
      componentName: 'CandidateCommentsComponent'
    },
    interview: {
      id: '#interview',
      componentName: 'CandidateInterviewComponent'
    },
    refapp: {
      id: '#refApp',
      componentName: 'RefAppComponent'
    },
    sriBgCheck: {
      id: '#sriBgCheck',
      componentName: 'SriBackgroundCheckComponent'
    },
    alva: {
      id: '#alva',
      componentName: 'AlvaLabsComponent'
    }
  };

  companyLanguage = this.setupService.companyLanguage;
  integratedWithTilTid = this.setupService.currentCompany.integratedWithTilTid;
  integratedWithRefapp = this.setupService.currentCompany.integratedWithRefapp;
  integratedWithSri = this.setupService.currentCompany.integratedWithSri;
  integratedWithAlva = this.setupService.currentCompany.integratedWithAlva;

  curentlyOpenedApplicationIndex: number;
  activeModal$: Observable<Modal> = this.modalService.activeModal$;

  isInterviewEnabled: boolean;
  displayExportButton: boolean;
  exportPlatforms: ExportPlatform[] = [];
  roles = Roles;
  alvaRolefit: AlvaNewRoleFit;
  alvaScoreMatch = AlvaScoreMatch;
  alvaCandidate: AlvalabsCandidate;
  previousAndCurrentCardStatus: number[];

  private _ngUnsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    modalService: ModalService,
    private el: ElementRef,
    private scrollToService: ScrollToService,
    private recruitmentService: RecruitmentService,
    private loaderService: LoaderService,
    private performAction: PerformActionService,
    private toastr: ToastrService,
    private errorHandlingService: ErrorHandlingService,
    private translateService: TranslateService,
    private setupService: SetupService,
    private exportService: ExportService,
    private cdr: ChangeDetectorRef,
    private notificationService: NotificationService,
    private route: ActivatedRoute,
    private agreementService: AgreementService
  ) {
    super(modalService);
    this.route.queryParams
      .pipe(
        takeUntil(this._ngUnsubscribe$))
      .subscribe(({ jobGuid, universalJob }: Params) => {
        this.jobIds = jobGuid?.split(',') || null;
        this.universalJobId = universalJob;
      });
  }

  get data(): CandidateCardModalData {
    return this.modal.data;
  }

  get applicationId(): number {
    return this.data.applicationId;
  }

  get allApplicationIds(): { id: number }[] {
    return this.data.allApplicationIds || [];
  }

  ngOnInit(): void {
    this.isInterviewEnabled = this.setupService.areInterviewsEnabled;
    this.trackRefreshedApplication();
    this.getApplication();
    this.areAgreementsEnabled = this.setupService.areAgreementsEnabled;

    this.recruitmentService.changedStatusesSubject$.pipe(
      takeUntil(this._ngUnsubscribe$)
    )
      .subscribe(statuses => {
        this.previousAndCurrentCardStatus = statuses;
      });
  }

  getApplication(): void {
    this.recruitmentService
      .getApplication(this.applicationId)
      .pipe(
        catchError((errorResponse: HttpErrorResponse) => {
          this.closeModal();
          return this.errorHandlingService.handleBackendError(errorResponse);
        }),
      )
      .subscribe((application: CandidateApplication) => {
        this.application = application;
        console.log(this.application);
        this.curentlyOpenedApplicationIndex = this.allApplicationIds.findIndex(app => app.id === this.applicationId);

        this.setExportPlatforms();
        this.setModuleVisibilityFlags();

        if (this.data.scrollToSection) {
          setTimeout(() => {
            this.scroll(this.data.scrollToSection);
          }, 300);
        }

        if (this.data.refreshRefAppNotifications) {
          this.notificationService.refreshRefAppNotifications();
        }

        if (this.data.refreshUploadedCVNotifications) {
          this.markSeenUploadedCVNotification();
        }

        if (this.data.refreshCandidateProvidedInfoNotifications) {
          this.markSeenCandidateProvidedInfoNotification();
        }

        if (this.data.refreshSriBgCheckNotifications) {
          this.notificationService.refreshSriBgCheckNotifications();
        }


        this.alvaRolefit = application.alvaAssessment?.roleFitResult;


        this.cdr.detectChanges();
      });
  }

  markSeenUploadedCVNotification(): void {
    this.notificationService.markSeenUploadedCVNotification(this.application.guid)
      .subscribe(() => {
        this.notificationService.refreshUploadedCVNotifications();
      });
  }

  markSeenCandidateProvidedInfoNotification(): void {
    this.notificationService.markSeenCandidateProvidedInfoNotification(this.application.guid)
      .subscribe(() => {
        this.notificationService.refreshCandidateProvidedInfoNotifications();
      });
  }

  trackRefreshedApplication(): void {
    this.recruitmentService.refreshedApplication$
      .pipe(
        takeUntil(this._ngUnsubscribe$)
      )
      .subscribe((application: CandidateApplication) => {
        this.application = application;
        this.cdr.detectChanges();
      });
  }

  setExportPlatforms(): void {
    this.exportPlatforms = this.exportService.getExportPlatforms(this.application);
    this.displayExportButton = !!this.exportPlatforms.find((platform: ExportPlatform) => !platform.isExported);
  }

  scroll(name: string): void {
    const { id, componentName } = this.scrollToComponents[name];
    const element = this.el.nativeElement.querySelector(id);

    this.scrollToService.candidateCardScrollTo$.next(componentName);

    setTimeout(() => {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, 300);
  }

  onAskForCv(): void {
    this.loaderService.show();
    this.performAction.getAskForCvEmailPreview(false, this.application.id)
      .pipe(
        catchError((errorResponse: HttpErrorResponse) =>
          this.errorHandlingService.handleBackendError(errorResponse)
        )
      )
      .subscribe((content: string) => {
        const data: UniversalModalData = {
          title: this.translateService.instant('BUTTONS.EMAIL_PREVIEW'),
          htmlContent: content,
          disableHtmlContent: true,
          confirmBtnTitle: this.translateService.instant('BUTTONS.PROCEED'),
          cancelBtnTitle: this.translateService.instant('BUTTONS.CANCEL'),
          isEmailPreview: true,
          confirm: () => this.askForCv()
        };
        this.modalService.addModal(new Modal(UniversalModalComponent, data));
        this.loaderService.hide();
      });
  }

  askForCv(): void {
    this.loaderService.show();
    this.performAction
      .askForCv(this.application)
      .pipe(
        catchError((errorResponse: HttpErrorResponse) =>
          this.errorHandlingService.handleBackendError(errorResponse)
        )
      )
      .subscribe(() => {
        this.performAction.markIndividualAction({ id: this.application.id, communicated: true });
        this.loaderService.hide();
        this.toastr.success(
          this.translateService.instant('RECRUITMENT.CANDIDATE_NOTIFIED_SUCCESS_MSG')
        );
      });
  }

  setModuleVisibilityFlags(): void {
    this.application.orderOfQuestionsModules
      .forEach((moduleName: string) => {
        this.modules[moduleName] = true;
      });

    this.cdr.detectChanges();
  }

  exportedToExternalPlatform(exportedPlatform: ExportPlatforms): void {
    this.exportPlatforms
      .map((platform: ExportPlatform) => {
        if (platform.propName === exportedPlatform) {
          platform.isExported = true;
        }
      });

    this.cdr.detectChanges();
  }

  closeModal(): void {
    this.modalService.removeModal(this.modal);
  }

  openPrevious(): void {
    if (this.curentlyOpenedApplicationIndex > 0) {
      this.openNew(-1);
    } else {
      this.closeModal();
    }
  }

  openNext(): void {
    if (this.curentlyOpenedApplicationIndex < this.allApplicationIds.length - 1) {
      this.openNew(1);
    } else {
      this.closeModal();
    }
  }

  openNew(addToIndex: number): void {
    this.closeModal();

    if (this.data.openedAsPreviousApplication) {
      const data: CandidateCardModalData = {
        applicationId: this.allApplicationIds[this.curentlyOpenedApplicationIndex + addToIndex].id,
        allApplicationIds: this.data.allApplicationIds,
        openedAsPreviousApplication: true
      };
      this.modalService.addModal(new CandidateCardModal(data));
    } else if (this.previousAndCurrentCardStatus?.length) {
      this.loaderService.show();
      const previousStatus = this.previousAndCurrentCardStatus[1];

      const request$ = this.application.isHidden ?
        this.recruitmentService.getAllStatusHiddenApplicationIds(previousStatus, this.jobIds, this.universalJobId)
        : this.recruitmentService.getAllStatusApplicationIds(previousStatus, this.jobIds, this.universalJobId);

      request$.subscribe((ids) => {
        const data: CandidateCardModalData = {
          applicationId: this.allApplicationIds[this.curentlyOpenedApplicationIndex + addToIndex].id,
          allApplicationIds: ids
        };
        this.modalService.addModal(new CandidateCardModal(data));
        this.previousAndCurrentCardStatus = [];
        this.loaderService.hide();
      });
    } else {
      this.recruitmentService.openCandidateModal(
        this.allApplicationIds[this.curentlyOpenedApplicationIndex + addToIndex].id,
        this.application.hiringStatus.id,
        this.application.isHidden
      );
    }
  }

  openEditPhoneModal(): void {
    const data: UpdatePhoneModalData = {
      phoneNumber: this.application.phoneNumber,
      guid: this.application.guid,
      phoneUpdated: (newPhoneNumber: string) => this.application.phoneNumber = newPhoneNumber
    };

    this.modalService.addModal(new Modal(UpdatePhoneModalComponent, data));
  }

  hideUnhideApplication(): void {
    this.loaderService.show();
    this.recruitmentService.hideUnhideApplication(this.application)
      .pipe(
        catchError((errorResponse: HttpErrorResponse) =>
          this.errorHandlingService.handleBackendError(errorResponse)
        ),
        finalize(() => {
          this.closeModal();
        })
      )
      .subscribe(() => {
        this.recruitmentService.setChangedStatuses([this.application.hiringStatus.id]);

        this.toastr.success(this.translateService.instant('JOBS.SUCCESS'));
        this.loaderService.hide();
      });
  }

  setAlvaCandidateInfo(alvaCandidate: AlvalabsCandidate): void {
    this.alvaCandidate = alvaCandidate;
  }

  openAgreementModal(id: number): void {
    this.loaderService.show();
    this.agreementService
      .getApplicationAgreementForCustomer(id)
      .subscribe((agreement: AgreementFormClass) => {
        const agreementData = {
          ...agreement,
        };
        this.loaderService.hide();
        this.modalService.addModal(new AgreementModal(agreementData));
      });
  }

  ngOnDestroy(): void {
    this._ngUnsubscribe$.next();
    this._ngUnsubscribe$.complete();
  }
}
